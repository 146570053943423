<template>
  <IseiVerification
    tenant-alias="platizhnyytsent"
    financial-model-alias="ka2bdbi1"
  >
    <template v-slot:header-image>
      <b-img
        center
        src="/images/platizhnyy-tsentr_logo_dark.svg"
        style="width: 50%"
      />
    </template>
    <template v-slot:header-text>
      ТОВ “Фінансова компанія “Платіжний Центр”
    </template>
    <template v-slot:system-link>
      <b-card-text>
        Для укладання договору на
        <a href="https://pay-center.com.ua/pochaty-spivpratsiu/" target="_blank"
          >отримання платіжних послуг</a
        >, просимо пройти електронну ідентифікацію за допомогою підсистеми
        верифікації ІСЕІ
      </b-card-text>
    </template>
  </IseiVerification>
</template>

<script>
import IseiVerification from "@/containers/IseiVerification.vue";
export default {
  name: "PlatizhnyyTsentr",
  components: {
    IseiVerification,
  },
};
</script>
